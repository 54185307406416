import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface Props {
  text: string | number | ReactNode;
  className?: string;
  level?: number;
  'aria-hidden'?: boolean;
  'aria-label'?: string;
}

const Title = (props: Props) => {
  const {
    text = '',
    className: propsClassName,
    level = 1,
    ...otherProps
  } = props;
  const className = classnames(styles.title, propsClassName);

  switch (level) {
    case 5:
      return (
        <h5 {...otherProps} className={className}>
          {text}
        </h5>
      );
    case 4:
      return (
        <h4 {...otherProps} className={className}>
          {text}
        </h4>
      );
    case 3:
      return (
        <h3 {...otherProps} className={className}>
          {text}
        </h3>
      );
    case 2:
      return (
        <h2 {...otherProps} className={className}>
          {text}
        </h2>
      );
    default:
      return (
        <h1 {...otherProps} className={className}>
          {text}
        </h1>
      );
  }
};

export default Title;
