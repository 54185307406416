import { Collapse } from 'antd';
import React from 'react';
import classNames from 'classnames';
import textParseHtml from 'technical/string/textParse';
import styles from './faqCollapse.module.scss';

export type Faq = {
  id: string;
  response: string;
  question: string;
};

type Props = {
  faqList: Faq[];
};

const FaqList = ({ faqList }: Props) => {
  return (
    <Collapse
      accordion
      expandIconPosition="end"
      className={styles.collapseContainer}
    >
      {faqList.map((faq) => (
        <Collapse.Panel
          header={
            <div className={classNames(styles.textCommon, styles.question)}>
              {faq.question}
            </div>
          }
          key={faq.id}
          className={styles.collapsePanel}
        >
          <p className={classNames(styles.textCommon, styles.response)}>
            {textParseHtml(faq.response)}
          </p>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default FaqList;
