import React, { useCallback } from 'react';
import classnames from 'classnames';
import { CourseFragment } from 'generated/graphql';
import { formatDurationInMinutes } from 'technical/duration';
import { LinkableContainer } from 'technical/link';
import Card from 'lms-ui/formation-card';
import Flex from 'lms-ui/flex';
import { Routes } from 'business/router/routes';
import { interpolateParams } from 'technical/router';
import { useTranslation } from 'next-i18next';
import {
  ArticleIcon,
  FileIcon,
  InteractiveContentIcon,
  PodcastIcon,
  VideoIcon,
} from 'lms-ui/src/icons';
import styles from './formation-card.module.scss';

interface Props {
  data: CourseFragment;
  className?: string;
}

function isMonomodule(data: CourseFragment) {
  if (
    data.sequences_aggregate.aggregate?.count === 1 &&
    data.sequences[0].sequence_modules_aggregate.aggregate?.count === 1
  ) {
    return true;
  }
  return false;
}

const FormationCard = ({ data, className }: Props) => {
  const {
    titleI18n,
    descriptionShortI18n,
    imageHeadUri,
    thematic,
    duration,
    id,
  } = data;

  const { t } = useTranslation();

  const computeMonoModuleFormation = useCallback(() => {
    if (
      data.sequences[0].sequence_modules[0].module.moduleContent
        ?.contentDocumentId
    ) {
      return {
        label: t('pages.cms.thematicBlock.moduleType.document'),
        icon: (
          <FileIcon alt={t('pages.cms.thematicBlock.modulType.alt.file')} />
        ),
      };
    }
    if (
      data.sequences[0].sequence_modules[0].module.moduleContent
        ?.contentPodcastId ||
      data.sequences[0].sequence_modules[0].module.moduleContent?.contentEmbed
        ?.subtype === 'podcast'
    ) {
      return {
        label: t('pages.cms.thematicBlock.moduleType.podcast'),
        icon: (
          <PodcastIcon
            alt={t('pages.cms.thematicBlock.modulType.alt.podcast')}
          />
        ),
      };
    }

    if (
      data.sequences[0].sequence_modules[0].module.moduleContent
        ?.contentVideoId ||
      data.sequences[0].sequence_modules[0].module.moduleContent?.contentEmbed
        ?.subtype === 'video'
    ) {
      return {
        label: t('pages.cms.thematicBlock.moduleType.video'),
        icon: (
          <VideoIcon alt={t('pages.cms.thematicBlock.modulType.alt.video')} />
        ),
      };
    }

    if (
      data.sequences[0].sequence_modules[0].module.moduleContent?.contentEmbedId
    ) {
      return {
        label: t('pages.cms.thematicBlock.moduleType.embed'),
        icon: (
          <InteractiveContentIcon
            alt={t('pages.cms.thematicBlock.modulType.alt.embed')}
          />
        ),
      };
    }

    return {
      label: t('pages.cms.thematicBlock.moduleType.article'),
      icon: (
        <ArticleIcon alt={t('pages.cms.thematicBlock.modulType.alt.article')} />
      ),
    };
  }, [data.sequences, t]);

  return (
    <div role="listitem">
      <Flex column className={classnames(styles.formationCard, className)}>
        <LinkableContainer
          href={interpolateParams(Routes.FormationPage, { courseId: id })}
        >
          <Card
            className={classnames(styles.formationCard, 'test-formation-card')}
            thematic={thematic?.labelI18n || ''}
            title={titleI18n || ''}
            duration={formatDurationInMinutes(duration || 0)}
            headImageUri={imageHeadUri || 'https://via.placeholder.com/150'}
            description={descriptionShortI18n || ''}
            titleClassName={styles.title}
            type={
              isMonomodule(data)
                ? computeMonoModuleFormation().label
                : t('pages.cms.thematicBlock.moduleType.formation')
            }
            typeLogo={
              isMonomodule(data) ? computeMonoModuleFormation().icon : undefined
            }
          />
        </LinkableContainer>
      </Flex>
    </div>
  );
};

export default FormationCard;
