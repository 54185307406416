import { useState } from 'react';

export interface PaginationConfig {
  resultsPerPage: number;
  currentPage: number;
}

interface Props extends PaginationConfig {
  onPageChange?: (config: PaginationConfig) => void;
}

function usePagination(
  config: Props = {
    currentPage: 1,
    resultsPerPage: 12,
  },
) {
  const [currentPage, setCurrentPage] = useState<number>(config.currentPage);
  const [resultsPerPage] = useState<number>(config.resultsPerPage);

  const setCustomCurrentPage = (page: number) => {
    setCurrentPage(page);
    if (config.onPageChange) {
      config.onPageChange({ resultsPerPage, currentPage: page });
    }
  };

  const reset = () => {
    setCurrentPage(1);
  };

  return {
    currentPage,
    resultsPerPage,
    setCurrentPage: setCustomCurrentPage,
    reset,
  };
}

export default usePagination;
