import { formatNumber } from 'technical/number/formatNumber';

export function splitDuration(minutes: number): {
  hours: number;
  minutes: number;
} {
  return { hours: Math.floor(minutes / 60), minutes: minutes % 60 };
}

export function formatDurationInMinutes(duration: number) {
  const splitedDuration = splitDuration(duration);

  if (splitedDuration.hours === 0 && splitedDuration.minutes === 0) {
    return `0min`;
  }

  const hours = splitedDuration.hours ? `${splitedDuration.hours}h` : '';
  const minutes = splitedDuration.minutes
    ? `${splitedDuration.minutes}min`
    : '';

  return `${hours}${minutes}`;
}
