import GridSpacerFrame from 'lms-ui/gridSpacer/frame';
import classnames from 'classnames';
import React from 'react';
import Screen from 'business/components/media/screenTypes';
import { CourseFragment, ThematicFragment } from 'generated/graphql';
import FormationGrid from 'business/course/components/formation-grid';
import ThematicFilters from 'business/home/components/culture/thematic-filters';
import ThematicHeader from 'business/home/components/culture/header';
import { useCultureHook } from 'business/home/components/culture/hooks';
import styles from './index.module.scss';

interface Props {
  courses: CourseFragment[];
  countCourses: number;
  thematics: ThematicFragment[];
}

const CultureBlock = ({ courses, countCourses, thematics }: Props) => {
  const {
    thematicId,
    updateThematicId,
    dataSource,
    loading,
    pagination,
    setCurrentPage,
  } = useCultureHook(courses, countCourses);

  return (
    <div>
      <div className={classnames(styles.mobileBlockHeader, Screen.Small)}>
        <ThematicHeader />
        {thematics.length > 0 ? (
          <ThematicFilters
            thematicId={thematicId}
            setThematicId={updateThematicId}
            thematics={thematics}
          />
        ) : null}
        <FormationGrid
          pagination={pagination}
          onPaginationChange={setCurrentPage}
          dataSource={dataSource}
          loading={loading}
        />
      </div>
      <GridSpacerFrame
        left
        right
        frameClassName={classnames(styles.gridFrameContainer, Screen.Medium)}
      >
        <ThematicHeader />
        {thematics.length > 0 ? (
          <ThematicFilters
            thematicId={thematicId}
            setThematicId={updateThematicId}
            thematics={thematics}
          />
        ) : null}
        <FormationGrid
          pagination={pagination}
          dataSource={dataSource}
          loading={false}
          onPaginationChange={setCurrentPage}
        />
      </GridSpacerFrame>
    </div>
  );
};

export default CultureBlock;
