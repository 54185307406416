export type IRouteParams = { [key: string]: any };

/**
 * Replace params with their real value in a dynamic URL
 *
 * @example
 *   interpolateParams('/user/[userId]', { userId: '25' }) === '/user/25'
 */
export function interpolateParams(route: string, params: IRouteParams = {}) {
  const queries: Array<[string, string]> = [];
  let url: string = route;

  Object.entries(params).forEach(([key, value]) => {
    const interpolatedParam = `[${key}]`;

    if (url.includes(interpolatedParam)) {
      url = url.replace(interpolatedParam, `${value}`);
    } else {
      queries.push([key, value]);
    }
  });

  // Add a leading '/' for legacy magic links
  url = url.startsWith('/') ? url : `/${url}`;

  // Remove the trailing '/' to mitigate a nextjs bug:
  // https://github.com/vercel/next.js/issues/5214
  url = url.endsWith('/') ? url.slice(0, -1) : url;

  if (queries.length > 0) {
    const queriesSearch = new URLSearchParams(queries);
    url = `${url}?${queriesSearch}`;
  }

  return url;
}
