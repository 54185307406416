import Title from 'business/components/common/title';
import Spacer from 'lms-ui/spacer';
import TypographyText from 'lms-ui/typography/text';
import { Trans, useTranslation } from 'next-i18next';
import styles from './header.module.scss';

const ThematicHeader = () => {
  const { t } = useTranslation();

  return (
    <Spacer direction="vertical" size="small">
      <Title
        text={
          <Trans
            i18nKey={t('pages.cms.thematicBlock.title')}
            components={{ blue: <div className={styles.blueText} /> }}
          />
        }
        className={styles.titleStyle}
      />
      <TypographyText className={styles.textCommon}>
        {t('pages.cms.thematicBlock.description')}
      </TypographyText>
    </Spacer>
  );
};

export default ThematicHeader;
