import { ApolloClient } from '@apollo/client';
import { CourseFragment } from 'generated/graphql';
import { getFileManager } from 'technical/fileManagement/hook';

export const loadUrlImages = async (
  apolloClient: ApolloClient<unknown>,
  courses: CourseFragment[],
) => {
  const fileManager = getFileManager(apolloClient);
  return Promise.all(
    courses.map(async (course) => {
      return {
        ...course,
        imageHeadUri: await fileManager.getPublicFile(
          course.imageHeadUri ?? '',
        ),
      };
    }),
  );
};
