import gql from 'graphql-tag';

const CourseFragment = gql`
  fragment Course on course {
    id
    imageHeadUri
    titleI18n
    descriptionShortI18n
    duration
    thematic {
      disabledAt
      labelI18n
    }
    sequences_aggregate {
      aggregate {
        count
      }
    }
    sequences {
      sequence_modules {
        module {
          moduleContent {
            contentEmbedId
            contentDocumentId
            contentVideoId
            contentPodcastId
            articleId
            contentEmbed {
              subtype
            }
          }
        }
      }
      sequence_modules_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const ThematicFragment = gql`
  fragment Thematic on thematic {
    id
    labelI18n
  }
`;

const ModuleFragment = gql`
  fragment Module on module {
    titleI18n
    descriptionI18n
    imageHeadUri
  }
`;

const ModuleAnnexesFragment = gql`
  fragment ModuleAnnexes on moduleAnnex {
    id
    annexOrder
    titleI18n
    descriptionI18n
    link
  }
`;

const ContentPodcastUploadsFragment = gql`
  fragment ContentPodcastUploads on contentPodcastUpload {
    id
    url
    size
    language
    transcriptUrl
    transcriptSize
  }
`;

const ContentAudioTranscriptsFragment = gql`
  fragment ContentAudioTranscripts on contentAudioTranscript {
    url
    size
    language
    id
  }
`;

const ContentVideoDetailsFragment = gql`
  fragment ContentVideoDetails on contentVideoDetails {
    videoId
    uploadUrl
    language
  }
`;

const ContentArticleFragment = gql`
  fragment ContentArticle on contentArticle {
    contentI18n
    contentId {
      translations {
        value
        locale
      }
    }
  }
`;

const ContentDocumentUploadsFragment = gql`
  fragment ContentDocumentUploads on contentDocumentUpload {
    id
    language
    url
    size
  }
`;

const ContentEventSlotsFragment = gql`
  fragment ContentEventSlots on contentEventSlot {
    id
    startDate
    type
    maxPlaces
    endDate
    address
    webexLink
    room
    descriptionI18n
    participantCount
  }
`;

const ContentCmi5AssignableUnitsFragment = gql`
  fragment ContentCmi5AssignableUnits on contentCmi5AssignableUnit {
    idCmi5
    language
    launchMethod
  }
`;

const ContentEmbedAudioTranscriptsFragment = gql`
  fragment ContentEmbedAudioTranscripts on contentEmbedAudioTranscript {
    url
    size
    language
    id
  }
`;

export const QUERY_MODULE = gql`
  query QueryModuleById(
    $courseId: uuid!
    $sequenceId: uuid!
    $moduleId: uuid!
  ) {
    course_by_pk(id: $courseId) {
      sequences(where: { id: { _eq: $sequenceId } }) {
        sequence_modules(where: { moduleId: { _eq: $moduleId } }) {
          module {
            ...Module
            moduleAnnexes(
              order_by: [{ annexOrder: asc }, { id: asc }]
              limit: 5
            ) {
              ...ModuleAnnexes
            }
            moduleContent {
              contentEmbed {
                link
                subtype
                contentEmbedAudioTranscripts(order_by: { id: asc }) {
                  ...ContentEmbedAudioTranscripts
                }
              }
              contentPodcast {
                id
                contentPodcastUploads(order_by: { id: asc }) {
                  ...ContentPodcastUploads
                }
              }
              contentVideo {
                id
                contentAudioTranscripts(order_by: { id: asc }) {
                  ...ContentAudioTranscripts
                }
                contentVideoDetails {
                  ...ContentVideoDetails
                }
              }
              contentArticle {
                ...ContentArticle
              }
              contentDocument {
                id
                contentDocumentUploads(order_by: { id: asc }) {
                  ...ContentDocumentUploads
                }
              }
              contentEvent {
                id
                contentEventSlots(order_by: { id: asc }) {
                  ...ContentEventSlots
                }
              }
              contentCmi5 {
                contentCmi5AssignableUnits(order_by: { id: asc }) {
                  ...ContentCmi5AssignableUnits
                }
              }
            }
          }
        }
      }
    }
  }
  ${ModuleFragment}
  ${ModuleAnnexesFragment}
  ${ContentPodcastUploadsFragment}
  ${ContentAudioTranscriptsFragment}
  ${ContentVideoDetailsFragment}
  ${ContentArticleFragment}
  ${ContentDocumentUploadsFragment}
  ${ContentEventSlotsFragment}
  ${ContentCmi5AssignableUnitsFragment}
  ${ContentEmbedAudioTranscriptsFragment}
`;

export const QUERY_MODULE_PROGRESSION = gql`
  query QueryUserModuleProgress($userId: uuid, $moduleId: uuid) {
    userModuleProgress(
      where: { userId: { _eq: $userId }, moduleId: { _eq: $moduleId } }
    ) {
      progression
    }
  }
`;

export const QUERY_MODULE_TASKS = gql`
  query QueryModuleTasksById($id: uuid!) {
    module_by_pk(id: $id) {
      moduleTasks(order_by: [{ taskOrder: asc }, { id: asc }]) {
        id
        taskOrder
        descriptionI18n
      }
    }
  }
`;

export const QUERY_COURSE_SEQUENCES = gql`
  query QueryCourseSequences($id: uuid!) {
    course_by_pk(id: $id) {
      category
      ## utilise pour la navigation
      sequences(order_by: { id: asc }) {
        sequence_modules {
          sequenceId
          orderInSequence
          moduleId
        }
        ## utilise pour la navigation mais meme issue que monomodule
        ## utilise pour le monomodule mais au final pas necessaire si on garde la partie du haut
        sequence_modules_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const PUBLISHED_CULTURE_COURSE = gql`
  query PublishedCultureCourses(
    $limit: Int!
    $offset: Int!
    $where: course_bool_exp
  ) {
    thematic(
      where: {
        disabledAt: { _is_null: true }
        courses: {
          category: { _eq: "culture" }
          publicationDate: { _lte: "NOW()" }
          status: { _eq: "published" }
        }
      }
      order_by: { id: asc }
    ) {
      ...Thematic
    }
    course_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    course(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { id: asc }
    ) {
      ...Course
    }
  }
  ${CourseFragment}
  ${ThematicFragment}
`;

export const MUTATION_START_CMI5_MODULE = gql`
  mutation startCmi5Content($input: StartCmi5ContentInput!) {
    startCmi5Content(input: $input) {
      success
      url
    }
  }
`;

export const MUTATION_PICK_EVENT_SLOT = gql`
  mutation saveCurrentUserEventSlot($input: SaveCurrentUserEventSlotInput!) {
    saveCurrentUserEventSlot(input: $input) {
      success
      message
    }
  }
`;

export const MUTATION_DELETE_USER_EVENT_SLOT = gql`
  mutation deleteUserFromEventSlot($input: DeleteUserFromEventSlotInput!) {
    deleteUserFromEventSlot(input: $input) {
      success
    }
  }
`;
