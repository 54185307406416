import { withAnySession } from 'business/authentication/next-helpers';
import Page from 'business/user/helpers/page';
import { CourseFragment, ThematicFragment } from 'generated/graphql';
import { GetServerSideProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Faq } from 'business/components/faq/faq-collapse';
import React from 'react';
import DocumentTitle from 'react-document-title';

import CultureBlock from 'business/home/components/culture';
import HeroBanner, { BannerData } from 'business/components/heroBanner';
import FaqContent from 'business/components/faq';
import SponsorBanner from 'business/components/sponsor';
import { loadFaqQuestions } from 'business/home/services/load-faq-questions';
import { loadBannerData } from 'business/home/services/load-banner-data';
import { loadCulturalCourses } from 'business/home/services/load-cultural-courses';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { CMSConnectGrid } from 'business/components/cmsPage/connect';
import { useTranslation } from 'next-i18next';

type Props = {
  faqList: Faq[];
  cultualCourses: CourseFragment[];
  countCulturalCourses: number;
  culturalCoursesThematics: ThematicFragment[];
  bannerData: BannerData;
  user: UserWithPrivateInfos | null;
};

const HomePage: NextPage<Props> = ({
  faqList,
  countCulturalCourses,
  cultualCourses,
  culturalCoursesThematics,
  bannerData,
  user,
}) => {
  const { t } = useTranslation();

  return (
    <DocumentTitle title={t('title.homePage')}>
      <Page>
        <HeroBanner data={bannerData} />
        {!user ? <CMSConnectGrid /> : null}
        {countCulturalCourses === 0 ? null : (
          <CultureBlock
            courses={cultualCourses}
            countCourses={countCulturalCourses}
            thematics={culturalCoursesThematics}
          />
        )}
        {faqList.length > 0 ? <FaqContent faqList={faqList} /> : null}
        <SponsorBanner />
      </Page>
    </DocumentTitle>
  );
};

export const getServerSideProps: GetServerSideProps = withAnySession<Props>()(
  async ({ apolloClient, locale = '', user }) => {
    const faqList = await loadFaqQuestions({ apolloClient, locale });

    const bannerData = await loadBannerData({ apolloClient, locale });

    const {
      courses: cultualCourses,
      countCourses: countCulturalCourses,
      thematics: culturalCoursesThematics,
    } = await loadCulturalCourses(apolloClient);

    return {
      props: {
        ...(await serverSideTranslations(locale)),
        faqList,
        cultualCourses,
        countCulturalCourses,
        culturalCoursesThematics,
        bannerData,
        user,
      },
    };
  },
);

export default HomePage;
