import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import config from 'config/index';
import styles from './index.module.scss';

declare global {
  interface Window {
    P2024_PARTNERS: any;
  }
}

// used to make default en easier
const lang: { [key: string]: 'en' | 'fr' | undefined } = {
  en: 'en',
  fr: 'fr',
};

const SponsorBanner: React.VFC = () => {
  const [status, setStatus] = useState<'idle' | 'loaded' | 'error'>('idle');

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.P2024_PARTNERS) {
      // eslint-disable-next-line no-new
      new window.P2024_PARTNERS('.js-partners');
    }
  }, [status]);

  if (!config.sponsor.footerStyle || !config.sponsor.footer) {
    return <div>sponsor could not load</div>;
  }

  const locale = lang[language] ?? lang.en;

  const updateId = new Date().getTime();

  return (
    <div className={styles.sponsor}>
      <div>
        <link
          rel="stylesheet"
          href={config.sponsor.footerStyle}
          type="text/css"
          media="all"
        />
      </div>
      <Script
        onLoad={() => setStatus('loaded')}
        onError={() => setStatus('error')}
        // Load the script later during browser idle time
        strategy="lazyOnload"
        type="text/javascript"
        src={`${config.sponsor.footer.replace(
          '{{locale}}',
          locale as string,
        )}?${updateId}`}
        id={`partners-js-${updateId}`}
        key={updateId}
      />
      <div className="js-partners" />
    </div>
  );
};

export default SponsorBanner;
