import React from 'react';

import { Trans, useTranslation } from 'next-i18next';
import classnames from 'classnames';

import GridSpacerSection from 'lms-ui/gridSpacer/section';
import GridSpacerFrame from 'lms-ui/gridSpacer/frame';
import Title from 'business/components/common/title';
import Spacer from 'lms-ui/spacer';
import Button from 'lms-ui/button';
import BigArrowHomePage from 'lms-ui/icons/bigArrowHomePage';
import TypographyText from 'lms-ui/typography/text';
import config from 'config/index';
import Screen from 'business/components/media/screenTypes';
import TabletFix from 'business/components/media/tabletFix';
import styles from './cmsTestPage.module.scss';

enum ConnexionContext {
  Employee = 'employee',
  P2024 = 'p2024',
}

const CMSConnect = () => {
  const { t } = useTranslation();

  return (
    <>
      <Spacer
        className={styles.firstBlock}
        direction="vertical"
        size="small"
        align="start"
      >
        <TypographyText className={styles.aca}>
          <Trans
            i18nKey={t('pages.cms.cmsConnect.description')}
            components={{ blue: <span className={styles.blueText} /> }}
          />
        </TypographyText>
      </Spacer>
      <Spacer direction="vertical" size="small" className={styles.secondBlock}>
        <Spacer direction="horizontal" size="small" align="baseline">
          <Title
            level={2}
            text={
              <Trans
                i18nKey={t('pages.cms.cmsConnect.volunteer')}
                components={{ blue: <span className={styles.blueText} /> }}
              />
            }
            className={styles.connectBlockTitle}
          />
        </Spacer>

        <Spacer
          direction="horizontal"
          size="small"
          align="center"
          className={styles.btnContainer}
        >
          <Button
            htmlType="button"
            type="primary"
            href={config.oidc.p2024ConnectLogin}
          >
            <Spacer direction="horizontal" size="small" align="center">
              <span
                aria-label={t('pages.signIn.aria', {
                  context: ConnexionContext.P2024,
                })}
              >
                {t('pages.cms.cmsConnect.connect')}
              </span>
            </Spacer>
          </Button>

          <a aria-hidden href={config.oidc.p2024ConnectLogin}>
            <BigArrowHomePage className={styles.arrowConnect} />
          </a>
        </Spacer>
      </Spacer>
      <Spacer direction="vertical" size="small" className={styles.secondBlock}>
        <Spacer direction="horizontal" size="small" align="baseline">
          <Title
            level={2}
            text={
              <Trans
                i18nKey={t('pages.cms.cmsConnect.salary')}
                components={{ blue: <span className={styles.blueText} /> }}
              />
            }
            className={styles.connectBlockTitle}
          />
        </Spacer>

        <Spacer
          direction="horizontal"
          size="small"
          align="center"
          className={styles.btnContainer}
        >
          <Button
            data-test-id="goToDashboardButton"
            type="primary"
            href={config.oidc.azureLogin}
            htmlType="button"
          >
            <Spacer direction="horizontal" size="small" align="center">
              <span
                aria-label={t('pages.signIn.aria', {
                  context: ConnexionContext.Employee,
                })}
              >
                {t('pages.cms.cmsConnect.connect')}
              </span>
            </Spacer>
          </Button>
          <a aria-hidden href={config.oidc.azureLogin}>
            <BigArrowHomePage className={styles.arrowConnect} />
          </a>
        </Spacer>
      </Spacer>
    </>
  );
};

export const CMSConnectDesktop = () => {
  return (
    <GridSpacerFrame left right top bottom>
      <GridSpacerSection>
        <CMSConnect />
      </GridSpacerSection>
    </GridSpacerFrame>
  );
};

export const CMSConnectMobile = () => {
  return (
    <GridSpacerFrame left right top bottom>
      <GridSpacerSection column>
        <CMSConnect />
      </GridSpacerSection>
    </GridSpacerFrame>
  );
};

export const CMSConnectGrid = () => {
  return (
    <div>
      <div className={classnames(Screen.Small, TabletFix.Tablet)}>
        <CMSConnectMobile />
      </div>
      <div className={classnames(Screen.Medium, TabletFix.NotTablet)}>
        <CMSConnectDesktop />
      </div>
    </div>
  );
};
