import Title from 'business/components/common/title';
import GridSpacerFrame from 'lms-ui/src/gridSpacer/frame';
import GridSpacerSection from 'lms-ui/src/gridSpacer/section';
import React from 'react';
import { Trans, useTranslation } from 'next-i18next';
import PetitDot from 'lms-ui/src/petitDot';
import Logo from 'lms-ui/src/logo';
import smiley from 'lms-ui/assets/logo/smileyBlue.svg';
import FaqList, { Faq } from 'business/components/faq/faq-collapse';

import styles from './faq.module.scss';

type Props = {
  faqList: Faq[];
};

const FaqContent = ({ faqList }: Props) => {
  const { t } = useTranslation();
  return (
    <GridSpacerFrame
      left="desktop"
      right="desktop"
      top
      bottom
      className={styles.faqFrame}
    >
      <GridSpacerFrame>
        <GridSpacerFrame bottom>
          <Title
            text={
              <Trans
                i18nKey={t('pages.dashboard.faq.title')}
                components={{ blue: <span className={styles.blue} /> }}
              />
            }
            className={styles.faqTitle}
          />
        </GridSpacerFrame>
        <GridSpacerSection column>
          <FaqList faqList={faqList} />
        </GridSpacerSection>
      </GridSpacerFrame>
      <GridSpacerFrame
        left
        frameClassName={styles.smileyFrame}
        className={styles.smileyFrameChildren}
      >
        <PetitDot className={styles.dotContent}>
          <Logo image={smiley} className={styles.blue} />
        </PetitDot>
      </GridSpacerFrame>
    </GridSpacerFrame>
  );
};

export default FaqContent;
