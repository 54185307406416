import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { loadUrlImages } from 'business/home/services/load-images';
import { PUBLISHED_CULTURE_COURSE } from 'business/module/services/queries.gql';
import {
  PublishedCultureCoursesQuery,
  PublishedCultureCoursesQueryVariables,
  CourseCategory,
} from 'generated/graphql';

export const culturePageLimit = 12;

export const loadCulturalCourses = async (
  apolloClient: ApolloClient<unknown>,
) => {
  const { data }: ApolloQueryResult<PublishedCultureCoursesQuery> =
    await apolloClient.query<
      PublishedCultureCoursesQuery,
      PublishedCultureCoursesQueryVariables
    >({
      query: PUBLISHED_CULTURE_COURSE,
      variables: {
        limit: culturePageLimit,
        offset: 0,
        where: {
          thematic: { disabledAt: { _is_null: true } },
          category: { _eq: CourseCategory.Culture },
        },
      },
    });
  const { course, course_aggregate: courseAggregate, thematic } = data;
  const countCourses = courseAggregate?.aggregate?.totalCount || 0;

  const courses = await loadUrlImages(apolloClient, course);
  return { countCourses, courses, thematics: thematic };
};
