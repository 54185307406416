import parse from 'html-react-parser';

const base64Regex =
  /^([A-Za-z0-9+\\/]{4})*([A-Za-z0-9+\\/]{3}=|[A-Za-z0-9+\\/]{2}==)?$/;

function textParseHtml(
  text?: string | null,
): string | JSX.Element | JSX.Element[] {
  if (text && base64Regex.test(text)) {
    return parse(text ? Buffer.from(text, 'base64').toString('binary') : '');
  }

  return parse(text ?? '');
}

export default textParseHtml;
