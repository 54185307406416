import { useCallback, useEffect, useState } from 'react';
import {
  usePublishedCultureCoursesLazyQuery,
  PublishedCultureCoursesQuery,
  CourseFragment,
  Course_Bool_Exp,
} from 'generated/graphql';
import usePagination, { PaginationConfig } from 'technical/pagination';
import useFileManager from 'technical/fileManagement/hook';
import { culturePageLimit } from 'business/home/services/load-cultural-courses';

function getWhereQuery(thematicId: string | undefined): Course_Bool_Exp {
  return {
    thematicId: thematicId ? { _eq: thematicId } : {},
    category: { _eq: 'culture' },
  };
}

export const useCultureHook = (
  preloadCourses: CourseFragment[],
  preloadCoursesCount: number,
) => {
  const [thematicId, setThematicId] = useState<string>();
  const [loadCourseList, { data, loading: queryLoading, called }] =
    usePublishedCultureCoursesLazyQuery();
  const [dataSource, setDataSource] =
    useState<CourseFragment[]>(preloadCourses);
  const [loading, setLoading] = useState<boolean>(false);
  const fileManager = useFileManager();

  const onPageChange = useCallback(
    ({ resultsPerPage, currentPage }: PaginationConfig) => {
      loadCourseList({
        variables: {
          limit: resultsPerPage,
          offset: resultsPerPage * (currentPage - 1),
          where: getWhereQuery(thematicId),
        },
      });
    },
    [loadCourseList, thematicId],
  );

  const { currentPage, resultsPerPage, setCurrentPage, reset } = usePagination({
    currentPage: 1,
    resultsPerPage: culturePageLimit,
    onPageChange,
  });

  const updateThematicId = useCallback(
    (id: string | undefined) => {
      loadCourseList({
        variables: {
          limit: resultsPerPage,
          offset: 0,
          where: getWhereQuery(id),
        },
      });

      setThematicId(id);
      reset();
    },
    [loadCourseList, reset, resultsPerPage],
  );

  const getCourseListTableData = useCallback(
    async (courses?: PublishedCultureCoursesQuery) => {
      setLoading(true);
      if (courses) {
        const loadCourses = await Promise.all(
          courses.course.map(async (course) => {
            return {
              ...course,
              thematic: {
                labelI18n: course.thematic?.disabledAt
                  ? null
                  : course.thematic?.labelI18n,
              },
              imageHeadUri: await fileManager.getPublicFile(
                course.imageHeadUri ?? '',
              ),
            };
          }),
        );
        setDataSource(loadCourses);
      }
      setLoading(false);
    },
    [fileManager],
  );

  const getListTotalResults = (courses?: PublishedCultureCoursesQuery) => {
    return courses?.course_aggregate.aggregate?.totalCount || 0;
  };

  useEffect(() => {
    updateThematicId(thematicId);
    getCourseListTableData(data);
  }, [data, getCourseListTableData, loadCourseList, resultsPerPage]);

  return {
    thematicId,
    updateThematicId,
    dataSource,
    loading: loading && queryLoading,
    pagination: {
      total: called ? getListTotalResults(data) : preloadCoursesCount,
      resultsPerPage,
      current: currentPage,
    },
    setCurrentPage,
  };
};
