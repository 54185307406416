import React from 'react';
import Row from 'lms-ui/grid/row';
import Col from 'lms-ui/grid/col';
import Loading from 'lms-ui/loading';
import { CustomPagination } from 'lms-ui/pagination';
import { CourseFragment } from 'generated/graphql';
import FormationCard from 'business/course/components/formation-card';
import { useTranslation } from 'next-i18next';
import styles from './index.module.scss';

interface Props {
  dataSource: CourseFragment[];
  pagination: {
    total: number;
    resultsPerPage: number;
    current: number;
  };
  onPaginationChange: (page: number) => void;
  loading: boolean;
}

const FormationGrid = ({
  loading,
  dataSource,
  pagination,
  onPaginationChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {loading ? <Loading /> : null}
      {!loading && dataSource.length > 0 ? (
        <div role="list">
          <Row gutter={5}>
            {dataSource.map((data) => (
              <Col
                xxl={6}
                xl={8}
                lg={12}
                md={12}
                key={data.id}
                className={styles.formationList}
              >
                <FormationCard
                  key={data.id}
                  data={data}
                  className={styles.formationCard}
                />
              </Col>
            ))}
          </Row>
          <CustomPagination
            defaultCurrent={1}
            total={pagination.total}
            current={pagination.current}
            pageSize={pagination.resultsPerPage}
            onChange={onPaginationChange}
            previousAriaLabel={t('common.pagination.previousAriaLabel')}
            nextAriaLabel={t('common.pagination.nextAriaLabel')}
            hideOnSinglePage
          />
        </div>
      ) : null}
    </>
  );
};

export default FormationGrid;
