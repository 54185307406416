import React from 'react';

import { BlueIconBanner } from 'lms-ui/src/icons/iconBanner';
import { CustomDownArrowIcon } from 'lms-ui/src/icons';
import classNames from 'classnames';
import Screen from 'business/components/media/screenTypes';
import TabletFix from 'business/components/media/tabletFix';
import styles from './hero.module.scss';

export type BannerData = {
  title: string;
  desktopImage: string;
  mobileImage: string;
};

type Props = {
  data: BannerData;
};

const HeroBanner = ({ data }: Props) => {
  return (
    <>
      <div className={classNames(styles.heroContainer, 'hero-banner-bg')}>
        <div className={styles.title}>{data.title}</div>
        <div className={styles.blueIconContainer}>
          <BlueIconBanner
            icon={<CustomDownArrowIcon aria-hidden alt="" />}
            reverse
            className={classNames(
              styles.blueIcon,
              Screen.Medium,
              TabletFix.NotTablet,
            )}
          />
        </div>
      </div>
      <style>
        {`
          .hero-banner-bg {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
              ),
              url('${
                data.desktopImage ? data.desktopImage : '/images/defaultJO.jpg'
              }');
          }

          @media (max-width: 767px) {
            .hero-banner-bg {
              background-image: linear-gradient(
                  rgba(0, 0, 0, 0.4),
                  rgba(0, 0, 0, 0.4)
                ),
                url('${
                  data.mobileImage ? data.mobileImage : '/images/defaultJO.jpg'
                }');
            }
          }
        `}
      </style>
    </>
  );
};

export default HeroBanner;
