import Flex from 'lms-ui/flex';
import { ThematicFragment } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';
import Button from 'lms-ui/button';
import { useMemo } from 'react';
import styles from './thematic-filters.module.scss';

interface Props {
  thematicId: string | undefined;
  setThematicId: (thematicId: string) => void;
  thematics: ThematicFragment[];
}

const ThematicFilters = ({ thematics, thematicId, setThematicId }: Props) => {
  const { t } = useTranslation();

  const listThematics = useMemo(() => {
    return [
      {
        id: null,
        labelI18n: t(`pages.home.thematicBlock.allThemeButtonText`),
      },
      ...thematics,
    ];
  }, [thematics, t]);

  return (
    <Flex className={styles.buttonList}>
      <div role="list">
        {listThematics.map(({ id, labelI18n }) => {
          return (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setThematicId(id);
              }}
              key={id}
              size="large"
              className={classnames(styles.thematicButton, {
                [styles.primary]:
                  thematicId === id ||
                  (id === null && thematicId === undefined),
                [styles.primaryOutline]: thematicId !== id,
              })}
              type="default"
              role="listitem"
            >
              {labelI18n}
            </Button>
          );
        })}
      </div>
    </Flex>
  );
};

export default ThematicFilters;
